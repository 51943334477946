@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './global/fonts.pcss';
@import './global/typo.pcss';
@import './global/general.pcss';
@import './global/layout.pcss';

/* @import './global/glightbox.pcss'; */

@import './components/components.pcss';
@import './components/buttons.pcss';
@import './components/header.pcss';
@import './components/footer.pcss';

@import './partials/landing.pcss';

@import './vendor/fontawesome.min.css';
@import './vendor/regular.min.css';
@import './vendor/solid.min.css';
@import './vendor/brands.min.css';

/* 
  tweaks for local development 
  these tags won't get to the production build
*/
if-not-language + if-language {
  display: none;
}

never-translate#languages button {
  max-width: 9.5ch;
  overflow: hidden;
}

html:not(.no-js) [data-slide-in] {
  @apply transition-[transform,opacity] not-viewport:opacity-0 not-viewport:translate-y-5;
}

html:not(.no-js) [data-fade-in] {
  @apply transition-[transform,opacity] not-viewport:opacity-0 not-viewport:scale-[98%];
}

#CookiebotWidget,
#crisp-chatbox {
  @apply !z-10;
}
